import { BaseService } from './baseService'
import apis from './apis'

export class PostService extends BaseService {
  static async getPostOffice(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.get_post_office,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async createPostOffice(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.create_post_office,
        pars
      )
      return response
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async detailPostOffice(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `${apis.detail_post_office}/${id}`
      )
      return response
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async editPostOffice(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.edit_post_office,
        pars
      )
      return response
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }
}
